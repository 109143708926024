import { useEffect } from "react";
import { useRouter } from "next/router";
import Spinner from "components/spinner";
import { useAuth } from "utils/hooks/useAuth";
import { kickUser } from "utils/userhandler";

const Home = () => {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    const userData = auth.user
      ? auth.user
      : JSON.parse(window.localStorage.getItem("userData"));
    if (userData && auth.user) {
      if (userData?.setupStatus == "pending") {
        router.replace("/register");
        return;
      }
    }
    // if (
    //   auth.user &&
    //   userData?.setupStatus !== "pending" &&
    //   auth.user?.scheduled
    // ) {
    //   router.replace("/pricing");
    //   return;
    // } else 
    if (auth.user && userData?.setupStatus !== "pending") {
      router.replace("/ai-funnels");
      return;
    } else {
      kickUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner sx={{ height: "100%" }} />;
};

export default Home;
